<script>
import TeachersService from '@/service/teachers.service';
import { PeriodSessionType } from '@/utils/enums/PeriodSessionType';

export default {
  name: 'list-teachers',

  components: {
    ModalRegisterTeacher: () => import('./ModalRegisterTeacher.vue'),
  },

  data() {
    return {
      loading: false,
      periodSessionType: PeriodSessionType,
      searchSession: 'All',
      options: {
        itemsPerPage: 10,
        sortBy: ['name'],
        sortDesc: [false],
        search: '',
      },

      totalItems: 0,
      items: [],

      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Foto', value: 'avatar', sortable: false, align: 'center', width: 80 },
        { text: 'Nome', value: 'name' },
        { text: 'Disciplina', value: 'disciplines', sortable: false },
        { text: 'Período', value: 'session', sortable: false },
        { text: 'Telefone', value: 'phone', sortable: false },
        { text: 'Ações', value: 'actions', sortable: false, align: 'right' },
      ],
    };
  },

  watch: {
    options: {
      handler() {
        this.fetch();
      },

      deep: true,
    },

    searchSession: {
      handler() {
        this.fetch();
      },
    },
  },

  methods: {
    async fetch() {
      this.loading = true;

      try {
        const filter = {
          page: this.options.page,
          limit: this.options.itemsPerPage,
          search: this.options.search,
        };

        if (this.searchSession && this.searchSession !== 'All') {
          filter['filter.session'] = `$eq:${this.searchSession}`;
        }

        if (this.options.sortBy.length > 0) {
          filter.sortBy = `${this.options.sortBy[0]}:${this.options.sortDesc[0] ? 'DESC' : 'ASC'}`;
        }

        const { data, meta } = await TeachersService.find(filter);
        this.items = data;
        this.page = meta?.currentPage;
        this.totalItems = meta?.totalItems;
      } catch (e) {
        console.log(e);
      }

      this.loading = false;
    },

    create() {
      this.$refs.modalRegister.$emit('create');
    },

    edit(teacher) {
      this.$refs.modalRegister.$emit('edit', teacher.id);
    },

    remove(teacher) {
      this.$swal({
        text: `Deseja remover '${teacher.name}'?`,
        icon: 'warning',
        confirmButtonText: 'SIM',
        showDenyButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return TeachersService.delete(teacher.id);
        },
      })
        .then((result) => {
          if (result.isConfirmed) {
            this.$swal.fire({
              icon: 'success',
              title: 'Removido!',
              text: 'Professor removido com sucesso!',
              timer: 2000,
              showConfirmButton: false,
            });
            this.fetch();
          }
        })
        .catch(() => {
          this.$swal.close();
        });
    },
  },
};
</script>

<template>
  <div class="mt-10">
    <h4 class="pt-4">Professores</h4>
    <v-card>
      <v-card-text>
        <v-row class="mb-0" align="center">
          <v-col cols="2">
            <v-btn color="primary" width="100%" @click="create">Novo Professor</v-btn>
          </v-col>
          <v-col cols="4" offset="4">
            <v-text-field
              v-model="options.search"
              append-icon="mdi-magnify"
              label="Pesquisar por nome ou disciplina"
              color="white"
              hide-details="auto"
              outlined
              dense
            />
          </v-col>
          <v-col cols="2" class="text-right">
            <v-select
              v-model="searchSession"
              :items="[
                {
                  text: 'Todos',
                  value: 'All',
                },
                {
                  text: 'Matutino',
                  value: 'MORNING',
                },
                {
                  text: 'Vespertino',
                  value: 'EVENING',
                },
                {
                  text: 'Noturno',
                  value: 'NOCTURNAL',
                },
                {
                  text: 'Integral',
                  value: 'INTEGRAL',
                },
              ]"
              label="Período"
              color="white"
              hide-details="auto"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          :loading="loading"
          :footer-props="{ 'items-per-page-options': [10, 30, 50, 100] }"
          class="no-line-row"
        >
          <template v-slot:[`item.avatar`]="{ item }">
            <v-avatar v-if="item.avatarUrl" class="avatar-teacher my-2" size="80">
              <img v-auth-image="item.avatarUrl" />
            </v-avatar>
          </template>

          <template v-slot:[`item.name`]="{ item }">
            <div class="subtitle-2">{{ item.name }}</div>
            <div>
              <i class="text--secondary">{{ item.email }}</i>
            </div>
          </template>

          <template v-slot:[`item.disciplines`]="{ item }">
            <span v-if="item.disciplines.length <= 0">--</span>
            <div v-if="item.disciplines.length > 0">
              <v-chip v-for="(d, i) in item.disciplines" :key="d.id" outlined>{{ d.name }}</v-chip>
            </div>
          </template>

          <template v-slot:[`item.session`]="{ item }">
            {{ periodSessionType[item.session] | empty }}
          </template>

          <template v-slot:[`item.phone`]="{ item }">
            <span>{{ item.phone | phone | empty }}</span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn icon title="Editar professor" @click="edit(item)">
              <v-icon small> mdi-pencil </v-icon>
            </v-btn>
            <v-btn icon color="error" title="Remover professor" @click="remove(item)">
              <v-icon small> mdi-delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <modal-register-teacher ref="modalRegister" @success="fetch" />
  </div>
</template>

<style scoped>
.avatar-teacher {
  max-width: 80px !important;
  min-width: unset !important;
  width: unset !important;
}
</style>
